<template>
  <el-container>
    <el-main class="c-login">
      <el-card class="box-card">
        <div class="icon">
          <img alt="Login" src="@/assets/person-icon.png" />
        </div>
        <div class="desc">
          <h3>Connectez-vous à votre compte</h3>
        </div>
        <el-alert v-if="error" :description="error" class="error" type="error" show-icon></el-alert>
        <el-form ref="form" :model="form" :rules="rules" status-icon @submit.native.prevent="submitForm">
          <el-form-item prop="username">
            <el-input v-model="form.username" placeholder="Nom d'utilisateur" autocomplete="on" autofocus="true" type="text" prefix-icon="el-icon-user" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" placeholder="Mot de passe" type="password" prefix-icon="el-icon-lock" />
          </el-form-item>
          <el-button type="primary" :loading="isLoading" :style="{ width: '100%' }" @click="submitForm">
            <span>Login</span>&nbsp;
            <i class="el-icon-arrow-right" />
          </el-button>
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { AUTH_REQUEST } from '@/store/modules/auth'

export default {
  name: 'Login',
  data: () => ({
    isLoading: false,
    form: {
      username: '',
      password: '',
      saveCredentials: true
    },
    rules: {
      username: [{ required: true, message: "Le nom d'utilisateur est obligatoire", trigger: 'blur' }],
      password: [{ required: true, message: 'Le mot de passe est obligatoire', trigger: 'blur' }]
    },
    error: null
  }),
  methods: {
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.isLoading = true
            await this.$store.dispatch(AUTH_REQUEST, this.form)
            if (this.$route.query.redirectTo) {
              this.$router.replace(this.$route.query.redirectTo)
            } else {
              this.$router.replace({ name: 'subtitles' })
            }
          } catch (e) {
            this.error =
              e.message == 'Failed to fetch'
                ? "Impossible de contacter le serveur, veuillez vous adresser à l'administrateur (info@swisstxt.ch)"
                : "Nom d'utilisateur ou mot de passe incorrect. Pour créer un nouveau compte, veuillez vous adresser à l'administrateur (info@swisstxt.ch)"
          } finally {
            this.isLoading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-login {
  display: flex;
  height: calc(100vh - 90px);
  align-items: center;
  justify-content: center;

  h3 {
    color: #858585;
  }

  > .box-card {
    position: relative;
    width: 400px;
    padding: 20px;

    .icon {
      text-align: center;
      margin-bottom: 30px;
      > img {
        width: 100px;
        opacity: 0.4;
      }
    }

    .desc {
      text-align: center;
      margin-bottom: 30px;
    }

    .el-form-item {
      margin-bottom: 30px;
    }
  }

  .el-alert {
    margin-bottom: 20px;
    .el-alert__description {
      margin-top: 0;
    }
  }
}
</style>
